<template>
  <div class="create-attachaments-container">
    <b-dropdown
      v-if="isWidget || isMobile"
      id="dropdown-media"
      size="sm"
      right
      variant="link"
      class="text-primary float-right mt-n50 mr-n75"
    >
      <template #button-content>
        <div class="d-flex flex-column align-items-center justify-content-center p-0 create-attachament--image">
          <feather-icon icon="ImageIcon" size="16" class="icon" />
          <span class="small mt-25 text-dark">
            Media
          </span>
        </div>
      </template>
      <b-dropdown-item
        class="create-attachament--image"
        @click="createNewPost('image')"
      >
        <feather-icon icon="ImageIcon" size="16" class="icon" />
        <span class="ml-50">
          {{ $t('posts.post-creation.attachments.image') }}
        </span>
      </b-dropdown-item>
      <b-dropdown-item
        class="create-attachament--video"
        @click="createNewPost('video')"
      >
        <feather-icon icon="VideoIcon" size="16" class="icon" />
        <span class="ml-50">
          {{ $t('posts.post-creation.attachments.video') }}
        </span>
      </b-dropdown-item>
      <b-dropdown-item
        class="create-attachament--document"
        @click="createNewPost('document')"
      >
        <feather-icon icon="FileTextIcon" size="16" class="icon" />
        <span class="ml-50">
          {{ $t('posts.post-creation.attachments.document') }}
        </span>
      </b-dropdown-item>
    </b-dropdown>
    <div v-else class="d-flex justify-content-around mx-5 px-5">
      <b-button
        variant="light"
        :disabled="disabled"
        class="create-attachament create-attachament--image p-1"
        @click="createNewPost('image')"
      >
        <feather-icon icon="ImageIcon" size="16" class="icon" />
        <span class="ml-50 d-none d-md-inline">
          {{ $t('posts.post-creation.attachments.image') }}
        </span>
      </b-button>
      <b-button
        variant="light"
        :disabled="disabled"
        class="create-attachament create-attachament--video p-1"
        @click="createNewPost('video')"
      >
        <feather-icon icon="VideoIcon" size="16" class="icon" />
        <span class="ml-50 d-none d-md-inline">
          {{ $t('posts.post-creation.attachments.video') }}
        </span>
      </b-button>
      <b-button
        variant="light"
        :disabled="disabled"
        class="create-attachament create-attachament--document p-1"
        @click="createNewPost('document')"
      >
        <feather-icon icon="FileTextIcon" size="16" class="icon" />
        <span class="ml-50 d-none d-md-inline">
          {{ $t('posts.post-creation.attachments.document') }}
        </span>
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';

export default {
  name: 'PostCreationAttachmentActions',
  components: {
    BButton,
  },
  props: {
    disabled: Boolean,
    isWidget: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isMobile() {
      return window.innerWidth < 700;
    },
  },
  methods: {
    createNewPost(attachamentType) {
      this.$emit('create-post', attachamentType);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.create-attachaments-container {
  .create-attachament {
    background-color: $white;
    border-color: $white;
    &:hover,
    &:active {
      background-color: $light;
      border-color: $light;
    }

    &--image .icon {
      color: $success;
    }
    &--video .icon {
      color: $warning;
    }
    &--document .icon {
      color: $info;
    }
    &.disabled {
      color: $text-muted;
      .icon {
        color: $text-muted;
      }
    }
  }
}
</style>
